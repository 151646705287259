import React from 'react';
import PageSection from 'ui-kit/page-section/page-section';
import PageLayout from 'components/layouts/page/page.layout';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { CenteredHero } from 'components/centered-text-hero/centered-hero.component';

const VerificationFailed = ({
    location,
    data
}: {
    location: Location;
    data: GatsbyTypes.VerificationFailedDataQuery;
}) => {
    const { t } = useTranslation();

    const params = new URLSearchParams(location.search);
    const flowParameter = params.get('flow');

    const renderBody = (flowParameter: string | null) => {
        if (flowParameter && flowParameter === 'auto-refill') {
            return `
                    <p>${t('pages.verificationFailed.autoRefillSubtitle.paragraphOne')}</p>
                    <p>${t('pages.verificationFailed.autoRefillSubtitle.paragraphTwo')}</p>
                    <p>${t('pages.verificationFailed.autoRefillSubtitle.paragraphThree')}</p>`;
        }
        return `
                <p>${t('pages.verificationFailed.subtitle.paragraphOne')}</p>
                <p>${t('pages.verificationFailed.subtitle.paragraphTwo')}</p>
        `;
    };

    return (
        <PageLayout metaData={{}}>
            <PageSection className="text-center">
                <CenteredHero
                    backgroundColor="white"
                    eyebrowText=""
                    image={data.easyRefillFlowDesktop}
                    linkTo="/sign-in"
                    linkLabel={t('pages.verificationFailed.buttonLabel')}
                    title={t('pages.verificationFailed.title')}
                    body={renderBody(flowParameter)}
                    subtitle=""
                    classNames="centered-hero--full-page flow-states"
                    sectionIndex={0}
                    dataGALocation="VerificationFailed-Signin"
                />
            </PageSection>
        </PageLayout>
    );
};

export default VerificationFailed;

export const query = graphql`
    query VerificationFailedData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        easyRefillFlowDesktop: file(relativePath: { eq: "assets/images/feather-background-white.jpeg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
